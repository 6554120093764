import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";
import { ContextProvider } from "./components/context/AppContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  const location = useLocation();

  useEffect(() => {
    // Trigger page view tracking whenever the route changes
    window.fbq('track', 'PageView');
  }, [location]);
  return (
    <ContextProvider>
      <Helmet>
        <title>Over E-commerce</title>
        <meta property="og:site_name" content="over" />
        <meta
          property="og:url"
          content="https://themeforest.net/user/ib-themes"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Over E-commerce" />
        <meta
          name="keywords"
          content="Over, e-commerce, fashion, stylish clothing, men’s fashion, women’s fashion, Egypt, online shopping, trendy clothes, modern apparel, clothing store, fashion boutique, online fashion store, urban fashion, casual wear, designer clothing, fashion retail, streetwear, fashion Egypt"
        />
        <meta
          name="description"
          content="(Over) is an online fashion brand in Egypt offering stylish and trendy clothing for men and women. Discover modern, fashionable apparel at affordable prices."
        />

        <meta name="description" content="Portfolio & Agency React Template" />
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      {/* End Scroll top */}

      <AllRoutes />
      <ToastContainer />
    </ContextProvider>
  );
};

export default App;
